const pedisol30 = []

const pedisol40 = [{
    'x': 124, 'y': 32
}, {
    'x': 96, 'y': 36
}, {
    'x': 69, 'y': 40
}, {
    'x': 135, 'y': 65
}, {
    'x': 108, 'y': 67
}, {
    'x': 77, 'y': 71
}, {
    'x': 46, 'y': 74
}, {
    'x': 140, 'y': 97
}, {
    'x': 105, 'y': 103
}, {
    'x': 73, 'y': 104
}, {
    'x': 38, 'y': 107
}, {
    'x': 134, 'y': 133
}, {
    'x': 98, 'y': 136
}, {
    'x': 65, 'y': 137
}, {
    'x': 32, 'y': 141
}, {
    'x': 129, 'y': 165
}, {
    'x': 97, 'y': 168
}, {
    'x': 66, 'y': 171
}, {
    'x': 33, 'y': 175
}, {
    'x': 114, 'y': 198
}, {
    'x': 87, 'y': 202
}, {
    'x': 58, 'y': 204
}, {
    'x': 32, 'y': 208
}, {
    'x': 85, 'y': 239
}, {
    'x': 49, 'y': 242
}, {
    'x': 95, 'y': 266
}, {
    'x': 64, 'y': 274
}, {
    'x': 96, 'y': 306
}, {
    'x': 61, 'y': 309
}, {
    'x': 106, 'y': 346
}, {
    'x': 77, 'y': 344
}, {
    'x': 48, 'y': 346
}, {
    'x': 115, 'y': 368
}, {
    'x': 81, 'y': 368
}, {
    'x': 48, 'y': 372
}, {
    'x': 122, 'y': 394
}, {
    'x': 86, 'y': 396
}, {
    'x': 51, 'y': 400
}, {
    'x': 106, 'y': 426
}, {
    'x': 68, 'y': 428
},]

const regions40 = {
    medialForefoot: [0, 1, 3, 4, 7, 8,],
    lateralForefoot: [2, 5, 6, 9, 10],
    calcaneus: [29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
    medialMidfoot: [11, 12, 15, 16, 19, 20, 23, 25, 27],
    lateralMidfoot: [13, 14, 17, 18, 21, 22, 24, 26, 28]
};


const thresholds = {
    stance: 50, forefoot: 80, midfoot: 70, calcaneus: 90
};

const stancePhases = ["Initial Contact", "Loading Response", "Mid-Stance", "Terminal Stance", "Pre-Swing", "Double Support"];

const swingPhases = ["Initial Swing", "Mid-Swing", "Late Swing"];


export {pedisol30, pedisol40, regions40, thresholds, stancePhases, swingPhases}
